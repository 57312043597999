html {
    scroll-behavior: smooth;
    font-size: min(2.5vh, 4.2vw);
    line-height: 1.4em;
}

body {
    margin: 0;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    color: #d4d4d4;
}
::-moz-selection {
    /* Code for Firefox */
    background: #0075FF;
}
::selection {
    background: #0075FF;
}

a {
    text-decoration: none;
    cursor: pointer;
}

h1 {
    font-size: 2.5em;
}

h2 {
    font-size: 1.4em;
}

.header {
    color: white;
    font-style: italic;
    margin: 0;
    line-height: 1.2em;
}

.subheader {
    color: white;
    font-family: Aleo, serif;
    font-weight: normal;
    margin: 0;
    line-height: 1.2em;
}

ul {
    margin-top: 2em;
    margin-bottom: 2em;
}

button {
    border: none;
    background-color: Transparent;
    outline: none;
    color: white;
    cursor: pointer;
    font-size: 1em;
    font-family: inherit;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

:focus-visible {
    outline: auto;
}

.focus-visible {
    outline: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
